import { Component, Mixins } from "vue-property-decorator";
import ModelMixin from "@/mixins/ModelMixin";
import { VueClass } from "vue-class-component/lib/declarations";
import { User, UserCollection } from "@planetadeleste/vue-mc-shopaholic";
import { delay } from "lodash";
import type { Subjects } from "@/services/ability";

const TypedModelMixin = ModelMixin as unknown as VueClass<
  ModelMixin<User, UserCollection>
>;

@Component
export default class UsersMixin extends Mixins(TypedModelMixin) {
  obModelClass = User;
  obCollection = new UserCollection();
  obCollectionClass = UserCollection;
  sRouteName = "users.list";

  get modelClassName(): Subjects {
    return "User";
  }

  created() {
    this.onCreated();
  }

  async deleteItem(iItemId: number) {
    const obModel: User | undefined = this.obCollection.find({
      id: iItemId,
    });
    if (!obModel) {
      return;
    }

    if (obModel.id == this.$user.get().id) {
      this.flashError(this.$t("error.cannot.remove.auth.user"));
      return;
    }

    this.remove(obModel);
  }

  onRegisterEvents() {
    this.addEvent("after.hide.form", () => {
      delay(() => {
        this.emit("reload.index");
      }, 1500);
    });
  }
}
